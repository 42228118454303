body {
	background-color: #460000;
}

.App {
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
}

.App::after {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-image: url("/public/Background.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	filter: blur(30px);
	z-index: -1;
}

.cover {
	width: min(80vw, 200px);
	display: block;
	margin: 50px auto;
	box-shadow: 0 0 20px #000c;
}

.audioElements {
	margin-left: auto;
	margin-right: auto;
	max-width: 500px;
	margin-bottom: 100px;
}

.audioElement {
	background: #0008;
	padding: 10px 20px;
	color: white;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	user-select: none;
	margin: 10px 10px;
}

.audioElement.active {
	background: #000a;
	font-weight: bold;
}

.audioElement:hover {
	background: #000a;
}

.rhap_container {
	position: fixed;
	bottom: 0;
	background-color: #0008;
	backdrop-filter: blur(10px);
}

.rhap_progress-indicator {
	background-color: #fff;
}

.rhap_progress-filled {
	background-color: #fff;
}

.rhap_download-progress {
	background-color: #7e7e7e;
}

.rhap_time {
	color: #fff;
}

.rhap_volume-indicator {
	background-color: #fff;
}

.rhap_button-clear {
	color: #fff;
}

.lds-facebook {
	display: inline-block;
	position: relative;
	width: 20px;
	height: 15px;
}

.lds-facebook div {
	display: inline-block;
	position: absolute;
	left: 2px;
	width: 4px;
	background: #fff;
	animation: lds-facebook 1.2s ease-in-out infinite;
}

.lds-facebook div:nth-child(1) {
	left: 2px;
	animation-delay: -0.8s;
}

.lds-facebook div:nth-child(2) {
	left: 8px;
	animation-delay: -0.4s;
}

.lds-facebook div:nth-child(3) {
	left: 14px;
	animation-delay: 0;
}

@keyframes lds-facebook {
	0%, 100% {
		top: 2px;
		height: 16px;
	}
	50% {
		top: 6px;
		height: 8px;
	}
}
